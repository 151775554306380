import React, { useState } from 'react';
import pink from '../assets/pink.jpg';
import black from '../assets/Thera Derm Icon-01.png';
import blue from '../assets/blue.jpg';
import { FaShoppingBag, FaBell, FaInstagram, FaFacebookF, FaTwitter } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { RxAvatar } from "react-icons/rx";
import chatIcon from '../assets/live-chat (1).png';
import ChatWindow from './chatWindow';
import axios from 'axios';
import loading from '../assets/Spinner@1x-1.0s-200px-200px.gif';
import ConfirmationNotification from './confirmationNotificatin';

const backendURL = 'https://theradermethiopia-backend.onrender.com';

const ComingSoon = () => {
    const [chat, setChat] = useState(false);
    const [email, setEmail] = useState('');
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleChat = () => {
        setChat(!chat);
    };

    const onSubmit = async (event) => {
        event.preventDefault(); // Prevent form default submission
        setSaving(true);
        try {
            const response = await axios.post(`${backendURL}/api/email/save`, { email });
            if (response.status === 201) {
                setSuccess(true);
            }
        } catch (error) {
            console.error('Error submitting email:', error);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className="flex flex-col min-h-screen">
            <header className="w-full bg-white py-4">
                <div className="container mx-auto flex justify-between items-start px-4 md:px-0">
                    <div className="relative w-full md:w-auto">
                        <input
                            type="search"
                            placeholder="Search..."
                            className="pl-8 border-b border-black px-3 py-1 mx-2 md:w-full rounded-none focus:outline-none focus:border-custom-pink"
                        />
                        <div className="absolute left-4 top-3">
                            <IoIosSearch />
                        </div>
                    </div>
                    <div className="text-3xl font-bold">
                        <img
                            src={black}
                            alt="Logo"
                            className="w-72 h-72 mr-36 hidden md:block"
                        />
                    </div>
                    <div className="flex items-center space-x-5">
                        <FaBell style={{ height: "20px", width: "20px" }} className='hover:cursor-pointer hover:text-custom-pink' />
                        <RxAvatar style={{ height: "20px", width: "20px" }} className='hover:cursor-pointer hover:text-custom-pink' />
                        <FaShoppingBag style={{ height: "20px", width: "20px" }} className='hover:cursor-pointer hover:text-custom-pink' />
                    </div>
                </div>
            </header>
    
            <main className="flex-grow flex flex-col items-center justify-center py-5 md:py-10 px-4">
                <h1 className="text-4xl md:text-6xl font-bold mb-4">We Are Coming Soon</h1>
                <p className="mb-8 text-center">Sign up to be the first to know when we go live.</p>
                <form className="flex flex-col items-center w-full max-w-md" onSubmit={onSubmit}>
                    <input
                        type="email"
                        placeholder="Enter your email here*"
                        onChange={(e) => setEmail(e.target.value)}
                        className="p-2 border-b border-custom-pink focus:outline-none focus:border-custom-pink w-full"
                    />
                    <button type="submit" className="bg-black text-white hover:text-black hover:bg-custom-pink transition duration-300 ease-in-out py-2 px-8 mt-4">
                        Notify Me
                    </button>
                </form>
                {saving && (
                    <div className='mt-4'>
                        <img src={loading} alt="Loading..." />
                    </div>
                )}
            </main>
    
            <div className="bg-white">
                <div className="container mx-auto py-5 px-4">
                    <div className="flex flex-wrap justify-center items-center mb-28">
                        <div className="flex flex-col items-center p-5 border-r border-dashed border-black">
                            <div className="text-lg font-bold text-black">FOLLOW US</div>
                            <div className="flex space-x-4 mt-4">
                                <a href="#" className="text-black hover:text-custom-blue">
                                    <FaFacebookF style={{ fontSize: "20px" }} className="hover:cursor-pointer" />
                                </a>
                                <a href="#" className="text-black hover:text-custom-pink">
                                    <FaTwitter style={{ fontSize: "20px" }} className="hover:cursor-pointer" />
                                </a>
                                <a href="#" className="text-black hover:text-custom-pink">
                                    <FaInstagram style={{ fontSize: "20px" }} className="hover:cursor-pointer" />
                                </a>
                            </div>
                        </div>
                        <div className="flex flex-col items-center p-5">
                            <h1 className="text-xl text-center">Coming Soon</h1>
                        </div>
                    </div>
                </div>
            </div>
         

         {/*    <div 
  className="fixed bottom-6 right-6 cursor-pointer p-3 bg-gradient-to-r from-pink-500 via-purple-500 to-chatBg rounded-full shadow-lg transition-transform transform hover:scale-110 flex items-center justify-center">
  <div className="bg-white rounded-full p-2 shadow-md">
    <img 
      src={chatIcon} 
      className='w-8 h-8' 
      alt="Chat Icon" 
      onClick={handleChat} 
    />
  </div>
</div>





            {chat && <ChatWindow className="flex justify-end sticky bottom-0 " />} */}
    
            <footer className="bg-black text-white py-6 text-center w-full sm:py-4 md:py-6 lg:py-8">
                <p>© 2024 by TheraDerm Ethiopia.</p>
            </footer>

            {/* Display the confirmation notification */}
            <ConfirmationNotification visible={success} onClose={() => setSuccess(false)} />
        </div>
    );
};

export default ComingSoon;
