import React, { useEffect } from 'react';
import { CheckIcon } from '@heroicons/react/solid';

const ConfirmationNotification = ({ visible, onClose }) => {
    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                onClose();
            }, 3000); // Hide the notification after 3 seconds
            return () => clearTimeout(timer); // Clean up timer on component unmount
        }
    }, [visible, onClose]);

    return (
        <div className={`fixed top-5 left-1/2 transform -translate-x-1/2 z-50 transition-transform duration-500 ${visible ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'} ease-in-out`}>
            <div className="flex items-center bg-gradient-to-r from-blue-500 to-teal-400 text-white p-4 rounded-lg shadow-2xl space-x-3">
                <CheckIcon className="w-6 h-6 text-white" />
                <span className="font-semibold">Form submitted successfully!</span>
            </div>
        </div>
    );
};

export default ConfirmationNotification;
